/* =================================================================
* Template JS
*
* Author:      Fluxpixel
* URL:         https://fluxpixel.com/
================================================================= */

(function ($) {
	'use strict';
// ========================================
// Detect browser and add class to </body>
// ========================================

// Detect Firefox
let firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;

// Add class "is-firefox" to </body>
if(firefoxAgent) {
    $("body").addClass("is-firefox");
}



// ==============================================
// Detect mobile device and add class to </body>
// ==============================================

// Detect mobile device (Do not remove!!!)
var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Nokia|Opera Mini/i.test(navigator.userAgent) ? true : false;

// Add class "is-mobile" to </body>
if(isMobile) {
    $("body").addClass("is-mobile");
}
// =================
	// Page transitions
	// =================

	if ($("body").hasClass("flux-transition")) {

		// Wait until the whole page is loaded.
		$(window).on("load", function () {
			setTimeout(function(){
				HideLoad(); // call out animations.
			}, 0);
		});


		// Transitions In (when "ptr-overlay" slides in).
		// =================
		function RevealLoad() {
			var tl_transitIn = gsap.timeline({ defaults: { duration: 1.5, ease: Expo.easeInOut }});

				if ($("#page-transition").length) {
					 tl_transitIn.set("#page-transition", { autoAlpha: 1 });
					 tl_transitIn.to(".ptr-overlay", { scaleY: 1, transformOrigin: "center bottom" }, 0);
					 tl_transitIn.to(".ptr-preloader", { autoAlpha: 1 }, 0.4);
				}
				tl_transitIn.to("#content-wrap", { y: -80, autoAlpha: 0 }, 0);
				tl_transitIn.to("#flux-header", { y: -20, autoAlpha: 0 }, 0);
		}


		// Transitions Out (when "ptr-overlay" slides out)
		// ================
		function HideLoad() {
			var tl_transitOut = gsap.timeline();

				 if ($("#page-transition").length) {
					tl_transitOut.to(".ptr-preloader", { duration: 1.5, autoAlpha: 0, ease: Expo.easeInOut });
					tl_transitOut.to(".ptr-overlay", { duration: 1.5, scaleY: 0, transformOrigin: "center top", ease: Expo.easeInOut }, 0.3);
				 }

				 // flux-Header appear
				 tl_transitOut.from("#flux-header", { duration: 1.5, y: 20, autoAlpha: 0, ease: Expo.easeInOut, clearProps:"all" }, 0.6);

				 // flux-Footer appear
				 tl_transitOut.from("#flux-footer", { duration: 1.5, y: 20, autoAlpha: 0, ease: Expo.easeInOut, clearProps:"all" }, 0.2);

				 // Page header image appear
				 if ($(".ph-image").length) {
				 	if ($("#page-header").hasClass("ph-bg-image")) {
				 		tl_transitOut.from(".ph-image img, .ph-video", { duration: 2, y: 80, autoAlpha: 0, stagger: 0.3, ease: Expo.easeOut, clearProps:"all" }, 0.8);
				 	} else {
				 		tl_transitOut.from(".ph-image", { duration: 2, y: 80, autoAlpha: 0, stagger: 0.3, ease: Expo.easeOut, clearProps:"all" }, 1.2);
				 	}
				 }

				 // Page header elements appear (elements with class "ph-appear")
				 var $phAppear = $(".ph-appear");
				 if ($phAppear.length) {
				 	tl_transitOut.from($phAppear, { duration: 2, y: 40, autoAlpha: 0, stagger: 0.3, ease: Expo.easeOut, clearProps:"all" }, 1.5);
				 }


				 // Sliding sidebar trigger appear
				 var $ssWrap = $(".flux-sliding-sidebar-wrap");
				 var $ssTrigger = $(".flux-sliding-sidebar-trigger");
				 if ($ssWrap.length) {
					 if(!isMobile) {
						 if ($ssWrap.hasClass("flux-ss-right")) {
						 	tl_transitOut.from($ssTrigger, { duration: 1.5, autoAlpha: 0, x: 50, ease: Expo.easeOut }, 2);
						 } else {
						 	tl_transitOut.from($ssTrigger, { duration: 1.5, autoAlpha: 0, x: -50, ease: Expo.easeOut }, 2);
						 }
					 } else {
					 	tl_transitOut.from($ssTrigger, { duration: 1.5, autoAlpha: 0, y: 20, ease: Expo.easeOut }, 2);
					 }
				 }

		}


		// Force page a reload when browser "Back" button click.
		// =====================================================
		window.onpageshow = function (event) {
			if (event.persisted) {
				window.location.reload();
			}
		}


		// On link click
		// ==============
		$("a")
			.not('[target="_blank"]') // omit from selection
			.not('[href^="#"]') // omit from selection
			.not('[href^="mailto"]') // omit from selection
			.not('[href^="tel"]') // omit from selection
			.not(".lg-trigger") // omit from selection
			.not(".flux-btn-disabled") // omit from selection
			.not(".no-transition") // omit from selection
			.on('click', function(e) {
				e.preventDefault();

				setTimeout(function (url) {
					window.location = url
				}, 1500, this.href);

				RevealLoad(); // call in animations.
		});

	}
if ($("body").hasClass("flux-smooth-scroll")) {

    // Not for mobile devices!
    if (!isMobile) {
        var Scrollbar = window.Scrollbar;

        // AnchorPlugin (URL with hash links load in the right position)
        // https://github.com/idiotWu/smooth-scrollbar/issues/440
        // ==================================
        class AnchorPlugin extends Scrollbar.ScrollbarPlugin {
            static pluginName = 'anchor';

            onHashChange = () => {
                this.jumpToHash(window.location.hash);
            };

            onClick = (event) => {
                const { target } = event;
                if (target.tagName !== 'A') {
                    return;
                }
                const hash = target.getAttribute('href');
                if (!hash || hash.charAt(0) !== '#') {
                    return;
                }
                this.jumpToHash(hash);
            };

            jumpToHash = (hash) => {
                if (!hash) {
                    return;
                }
                const { scrollbar } = this;
                scrollbar.containerEl.scrollTop = 0;
                const target = document.querySelector(hash);
                if (target) {
                    scrollbar.scrollIntoView(target, {
                        offsetTop: parseFloat(target.getAttribute('data-offset')) || 0 // Change to set default offset
                    });
                }
            };

            onInit() {
                this.jumpToHash(window.location.hash);
                window.addEventListener('hashchange', this.onHashChange);
                this.scrollbar.contentEl.addEventListener('click', this.onClick);
            };

            onDestroy() {
                window.removeEventListener('hashchange', this.onHashChange);
                this.scrollbar.contentEl.removeEventListener('click', this.onClick);
            };
        };

        // usage
        Scrollbar.use(AnchorPlugin);


        // Init Smooth Scrollbar
        // ======================
        Scrollbar.init(document.querySelector("#scroll-container"), {
            damping: 0.05,
            renderByPixel: true,
            continuousScrolling: true,
            alwaysShowTracks: true
        });


        // 3rd party library setup
        // More info: https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.scrollerProxy()
        // ========================
        let scrollPositionX = 0,
            scrollPositionY = 0,
            bodyScrollBar = Scrollbar.init(document.getElementById("scroll-container"));

        bodyScrollBar.addListener(({ offset }) => {
            scrollPositionX = offset.x;
            scrollPositionY = offset.y;
        });

        bodyScrollBar.setPosition(0, 0);
        bodyScrollBar.track.xAxis.element.remove();

        // tell ScrollTrigger to use these proxy getter/setter methods for the "body" element:
        ScrollTrigger.scrollerProxy("body", {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        // when smooth scroller updates, tell ScrollTrigger to update() too. 
        bodyScrollBar.addListener(ScrollTrigger.update);


        if (document.querySelector('.flux-header-absolute')) {
            // Add the updated header script inside the if block
            let lastOffsetY = 0; // Initialize last scroll position

            bodyScrollBar.addListener(({ offset }) => {
                var header = document.getElementById('flux-header');
                var about = document.getElementById('about');

                if (header && about) {
                var aboutTopPosition = about.getBoundingClientRect().top;
                var st = offset.y; // Get current scroll position

                if (aboutTopPosition <= 0) {
                    if (!header.classList.contains('flux-header-fixed')) {
                    header.classList.remove('flux-header-absolute');
                    header.classList.add('flux-header-fixed');
                    header.style.transition = ""; // reset the transition style when scrolling down
                    }
                } else if (st < lastOffsetY) {
                    if (!header.classList.contains('flux-header-absolute')) {
                    // animate slide-up
                    header.style.top = `-${header.offsetHeight}px`; // Initially position the header outside of the viewport
                    header.style.transition = "top 0.4s"; // add transition style

                    setTimeout(function () {
                        header.classList.remove('flux-header-fixed');
                        header.classList.add('flux-header-absolute');
                        header.style.top = '0px'; // Animate it sliding into view
                    }, 50);
                    }
                }

                lastOffsetY = st <= 0 ? 0 : st; // For Mobile or negative scrolling, update the last scroll position
                }
            });

            // Move "flux-header" out of "scroll-container"
            // Expl: Since Smooth Scrollbar doesn't support element fixed position inside "scroll-container" move the "flux-header" out of it.
            // ==========================================
            var header = $("#flux-header");
            var scrollContainer = $("#scroll-container");
            var headerFixedClass = "flux-header-fixed";
            var pageHeader = $("#page-header");
            var lastScrollPosition = 0;
            var headerMoved = false;

            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                if (mutation.attributeName === "class") {
                    var hasFixedClass = header.hasClass(headerFixedClass);
                    if (hasFixedClass && !headerMoved) {
                    headerMoved = true;
                    scrollContainer.prepend(header);
                    } else if (!hasFixedClass && headerMoved) {
                    headerMoved = false;
                    pageHeader.after(header);
                    }
                }
                });
            });

            observer.observe(header[0], { attributes: true });

            $(window).scroll(function () {
                var currentScrollPosition = $(this).scrollTop();
                var scrollUp = currentScrollPosition < lastScrollPosition;

                if (scrollUp && pageHeader.length > 0) {
                var pageHeaderBottom = pageHeader.offset().top + pageHeader.outerHeight();
                var headerBottom = header.offset().top + header.outerHeight();

                if (currentScrollPosition <= pageHeaderBottom && headerBottom > pageHeaderBottom) {
                    header.removeClass(headerFixedClass);
                }
                }

                lastScrollPosition = currentScrollPosition;
            });
            }

    }
    
    else {
        // Code inside the else block, to be executed only when #flux-header-absolute exists

        const fluxHeaderAbsolute = document.getElementById('flux-header-absolute');
        if (fluxHeaderAbsolute) {
            // Mobile header modification code
            let lastScrollPositionMobile = 0;
            const headerMobile = $("#flux-header");
            const pageHeaderMobile = $("#page-header");
            const headerFixedClassMobile = "flux-header-fixed";
            const headerHiddenClassMobile = "flux-header-hidden";
            const pageHeaderBottomMobile = pageHeaderMobile.offset().top + pageHeaderMobile.outerHeight();
            let isHeaderFixedMobile = false;

            $(window).scroll(function () {
            const currentScrollPosition = $(this).scrollTop();
            const scrollDown = currentScrollPosition > lastScrollPositionMobile;

            if (scrollDown && currentScrollPosition > pageHeaderBottomMobile) {
                headerMobile.addClass(headerFixedClassMobile);
                isHeaderFixedMobile = true;
            } else if (isHeaderFixedMobile && currentScrollPosition <= pageHeaderBottomMobile) {
                headerMobile.removeClass(headerFixedClassMobile);
                isHeaderFixedMobile = false;
            }

            if (scrollDown) {
                headerMobile.removeClass(headerHiddenClassMobile);
            } else {
                headerMobile.addClass(headerHiddenClassMobile);
            }

            lastScrollPositionMobile = currentScrollPosition;
            });

            // Intersection Observer to trigger header state change when page header is in view
            const observerMobile = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                if (entry.target === pageHeaderMobile[0]) {
                    if (entry.isIntersecting) {
                    headerMobile.removeClass(headerFixedClassMobile);
                    isHeaderFixedMobile = false;
                    } else if (!isHeaderFixedMobile) {
                    headerMobile.addClass(headerFixedClassMobile);
                    isHeaderFixedMobile = true;
                    }
                }
                });
            },
            { threshold: [0] }
            );

            observerMobile.observe(pageHeaderMobile[0]);
        }
        }


}
// ==================================================
// Image lazy loading
// ==================================================

ScrollTrigger.config({ limitCallbacks: true });

gsap.utils.toArray(".flux-lazy").forEach(image => {
    
    let newSRC = image.dataset.src,
            newImage = document.createElement("img"),

    loadImage = () => {
        newImage.onload = () => {
            newImage.onload = null; // avoid recursion
            newImage.src = image.src; // swap the src
            image.src = newSRC;
            // place the low-res version on TOP and then fade it out.
            gsap.set(newImage, {
                position: "absolute", 
                top: image.offsetTop, 
                left: image.offsetLeft, 
                width: image.offsetWidth, 
                height: image.offsetHeight
            });
            image.parentNode.appendChild(newImage);
            gsap.to(newImage, {
                opacity: 0, 
                onComplete: () => {
                    newImage.parentNode.removeChild(newImage);
                    image.removeAttribute("data-src"); // remove "data-src" attribute if image is loaded
                }
            });
            st && st.kill();
        }
        newImage.src = newSRC;

        ScrollTrigger.refresh(true);
    }, 

    st = ScrollTrigger.create({
        trigger: image,
        start: "-50% bottom",
        onEnter: loadImage,
        onEnterBack: loadImage // make sure it works in either direction
    });
});
// ==================================================
// Main menu (classic)
// ==================================================

// Keeping sub-menus inside screen (useful if multi level sub-menus are used). Effect on large screens only!
// More info: http://stackoverflow.com/questions/17985334/jquery-solution-for-keeping-dropdown-dropdown-inside-screen
if ($(window).width() > 1024) {
    $(".flux-submenu-trigger").parent().on("mouseenter", function() {
        var menu = $("> .flux-submenu", this);
        var menupos = $(menu).offset();

        if (menupos.left + menu.width() > $(window).width()) {
            var newpos = -$(menu).width();

            menu.css({ left: newpos });    
        }
    });
}

// Main menu hover
$(".flux-main-menu-list").on("mouseenter", function() {
    $(this).addClass("flux-mm-hover");
}).on("mouseleave", function() {
    $(this).removeClass("flux-mm-hover");
});

// Submenu wrap hover
$(".flux-submenu-wrap").on("mouseenter", function() {
    $(this).addClass("flux-submenu-open");
}).on("mouseleave", function() {
    $(this).removeClass("flux-submenu-open");
});


// Mobile menu (for classic menu)
// ===============================

// Open/close mobile menu on toggle button click
$("#flux-m-menu-toggle-btn-wrap").on("click", function() {
    $("html").toggleClass("flux-no-scroll");
    $("body").toggleClass("flux-m-menu-open").addClass("flux-m-menu-active");
    if ($("body").hasClass("flux-m-menu-open")) {

        // Menu step in animations
        $("body").addClass("flux-m-menu-toggle-no-click"); // Disable toggle button click until the animations last.

        // Menu in animations
        var tl_MenuIn = gsap.timeline({
            onComplete: function() { 
                $("body").removeClass("flux-m-menu-toggle-no-click"); 
            }
        });

                tl_MenuIn.to(".flux-main-menu", { duration: 0.4, autoAlpha: 1 });
                tl_MenuIn.from(".flux-main-menu-content > ul > li", { duration: 0.4, y: 80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut, clearProps:"all" });

        // On menu link click
        $(".flux-main-menu a, .flux-logo a")
        .not('[target="_blank"]') // omit from selection
        .not('[href^="mailto"]') // omit from selection
        .not('[href^="tel"]') // omit from selection
        .on('click', function(event) {
            var href = $(this).attr("href");

            if (href.startsWith("#")) {
            // The link is a hash link, so close the menu without triggering the default behavior of the link
            event.preventDefault();
            closeMenu();
            } else {
            // The link is not a hash link, so animate the menu out and redirect to the link's href
            gsap.set("#content-wrap", { autoAlpha: 0 });
            gsap.to(".flux-main-menu-content > ul > li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
            setTimeout(function() {
                window.location.href = href;
            }, 900);
            }
        });

        function closeMenu() {
        // Get the href of the currently active link before closing the menu
        var activeLinkHref = $(".flux-main-menu a.flux-m-menu-active").attr("href");

        // Menu step out animations
        $("body").addClass("flux-m-menu-toggle-no-click"); // Disable toggle button click until the animations last.

        // Menu out animations
        var tl_MenuOut = gsap.timeline({
            onComplete: function () {
            $("body").removeClass("flux-m-menu-toggle-no-click");
            // Update the URL with the original anchor name
            history.pushState({}, '', activeLinkHref);
            }
        });

        tl_MenuOut.to(".flux-main-menu-content > ul > li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
        tl_MenuOut.to(".flux-main-menu", { duration: 0.4, autoAlpha: 0, clearProps: "all" }, "+=0.2");
        tl_MenuOut.to(".flux-main-menu-content > ul > li", { clearProps: "all" }); // clearProps only

        // Close open submenus
        setTimeout(function () {
            $(".flux-submenu").slideUp(350);
            $(".flux-submenu-trigger").removeClass("flux-m-submenu-open");
            $("body").removeClass("flux-m-menu-active");
            $("body").removeClass("flux-m-menu-open");
            $("body").removeClass("flux-m-menu-active");
            $("html").removeClass("flux-no-scroll");
        }, 900);
        }

    } else {	

        // Menu step out animations
        $("body").addClass("flux-m-menu-toggle-no-click"); // Disable toggle button click until the animations last.

        // Menu out animations
        var tl_MenuOut = gsap.timeline({
            onComplete: function() { 
                $("body").removeClass("flux-m-menu-toggle-no-click"); 
            }
        });

                tl_MenuOut.to(".flux-main-menu-content > ul > li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
                tl_MenuOut.to(".flux-main-menu", { duration: 0.4, autoAlpha: 0, clearProps:"all" }, "+=0.2");
                tl_MenuOut.to(".flux-main-menu-content > ul > li", { clearProps:"all" }); // clearProps only

        // Close open submenus
        setTimeout(function () {
            $(".flux-submenu").slideUp(350);
            $(".flux-submenu-trigger").removeClass("flux-m-submenu-open");
            $("body").removeClass("flux-m-menu-active");
        }, 900);
    }

    return false;
});

// Append element if link href contains #
$('.flux-submenu-trigger > a[href^="#"]').parent(".flux-submenu-trigger").append('<span class="flux-submenu-trigger-m"></span>');
// ============================================================================
// Isotope
// More info: http://isotope.metafizzy.co
// Note: "imagesloaded" blugin is required! https://imagesloaded.desandro.com/
// ============================================================================

// init Isotope
var $container = $(".isotope-items-wrap");
$container.imagesLoaded(function() {
    $container.isotope({
        itemSelector: ".isotope-item",
        layoutMode: "packery",
        transitionDuration: "0.7s",
        percentPosition: true
    });

    setTimeout(function() {
        $container.isotope('layout'); // Refresh Isotope
        ScrollTrigger.refresh(true); // Refresh ScrollTrigger
    }, 500);
});

// Filter
$(".ttgr-cat-list > li > a").on("click", function() {
    var selector = $(this).attr("data-filter");
    $container.isotope({
        filter: selector
    });

    // Refresh ScrollTrigger
    setTimeout(function() {
        ScrollTrigger.refresh(true);
    }, 500);

    return false;
});

// Filter item active
var filterItemActive = $(".ttgr-cat-list > li > a");
filterItemActive.on("click", function(){
    var $this = $(this);
    if ( !$this.hasClass("active")) {
        filterItemActive.removeClass("active");
        $this.addClass("active");
    }
});
// =====================================================
// lightGallery (lightbox plugin)
// Source: http://sachinchoolur.github.io/lightGallery
// Mousewheel plugin: https://github.com/jquery/jquery-mousewheel
// =====================================================

$(".lightgallery").lightGallery({

    // Please read about gallery options here: http://sachinchoolur.github.io/lightGallery/docs/api.html

    // lightGallery core 
    selector: '.lg-trigger',
    mode: 'lg-fade', // Type of transition between images ('lg-fade' or 'lg-slide').
    height: '100%', // Height of the gallery (ex: '100%' or '300px').
    width: '100%', // Width of the gallery (ex: '100%' or '300px').
    iframeMaxWidth: '100%', // Set maximum width for iframe.
    loop: true, // If false, will disable the ability to loop back to the beginning of the gallery when on the last element.
    speed: 600, // Transition duration (in ms).
    closable: true, // Allows clicks on dimmer to close gallery.
    escKey: true, // Whether the LightGallery could be closed by pressing the "Esc" key.
    keyPress: true, // Enable keyboard navigation.
    hideBarsDelay: 3000, // Delay for hiding gallery controls (in ms).
    controls: true, // If false, prev/next buttons will not be displayed.
    mousewheel: true, // Chane slide on mousewheel.
    download: false, // Enable download button. By default download url will be taken from data-src/href attribute but it supports only for modern browsers. If you want you can provide another url for download via data-download-url.
    counter: true, // Whether to show total number of images and index number of currently displayed image.
    swipeThreshold: 50, // By setting the swipeThreshold (in px) you can set how far the user must swipe for the next/prev image.
    enableDrag: true, // Enables desktop mouse drag support.
    enableTouch: true, // Enables touch support.
    getCaptionFromTitleOrAlt: false, // Option to get captions from alt or title tags.

    // Thumbnail plugin
    thumbnail: false, // Enable thumbnails for the gallery.
    showThumbByDefault: false, // Show/hide thumbnails by default.
    thumbMargin: 5, // Spacing between each thumbnails.
    toogleThumb: true, // Whether to display thumbnail toggle button.
    enableThumbSwipe: true, // Enables thumbnail touch/swipe support for touch devices.
    exThumbImage: 'data-exthumbnail', // If you want to use external image for thumbnail, add the path of that image inside "data-" attribute and set value of this option to the name of your custom attribute.

    // Autoplay plugin
    autoplay: false, // Enable gallery autoplay.
    autoplayControls: true, // Show/hide autoplay controls.
    pause: 6000, // The time (in ms) between each auto transition.
    progressBar: true, // Enable autoplay progress bar.
    fourceAutoplay: false, // If false autoplay will be stopped after first user action

    // Full Screen plugin
    fullScreen: true, // Enable/Disable fullscreen mode.

    // Zoom plugin
    zoom: false, // Enable/Disable zoom option.
    scale: 0.5, // Value of zoom should be incremented/decremented.
    enableZoomAfter: 50, // Some css styles will be added to the images if zoom is enabled. So it might conflict if you add some custom styles to the images such as the initial transition while opening the gallery. So you can delay adding zoom related styles to the images by changing the value of enableZoomAfter.

    // Video options
    videoMaxWidth: '1400px', // Set limit for video maximal width.

    // Youtube video options
    loadYoutubeThumbnail: true, // You can automatically load thumbnails for youtube videos from youtube by setting loadYoutubeThumbnail true.
    youtubeThumbSize: 'default', // You can specify the thumbnail size by setting respective number: 0, 1, 2, 3, 'hqdefault', 'mqdefault', 'default', 'sddefault', 'maxresdefault'.
    youtubePlayerParams: { // Change youtube player parameters: https://developers.google.com/youtube/player_parameters
        modestbranding: 0,
        showinfo: 1,
        controls: 1
    },

    // Vimeo video options
    loadVimeoThumbnail: true, // You can automatically load thumbnails for vimeo videos from vimeo by setting loadYoutubeThumbnail true.
    vimeoThumbSize: 'thumbnail_medium', // Thumbnail size for vimeo videos: 'thumbnail_large' or 'thumbnail_medium' or 'thumbnail_small'.
    vimeoPlayerParams: { // Change vimeo player parameters: https://developer.vimeo.com/player/embedding#universal-parameters 
        byline : 1,
        portrait : 1,
        title: 1,
        color : 'CCCCCC',
        autopause: 1
    },

    // Hash plugin (unique url for each slides)
    hash: false, // Enable/Disable hash plugin.
    hgalleryId: 1, // Unique id for each gallery. It is mandatory when you use hash plugin for multiple galleries on the same page.

    // Rotate plugin
    rotate: false,

    // Share plugin
    share: false, // Enable/Disable share plugin.
        facebook: true, // Enable Facebook share.
        facebookDropdownText: 'Facebook', // Facebok dropdown text.
        twitter: true, // Enable Twitter share.
        twitterDropdownText: 'Twitter', // Twitter dropdown text.
        googlePlus: true, // Enable Google Plus share.
        googlePlusDropdownText: 'Google+', // Google Plus dropdown text.
        pinterest: true, // Enable Pinterest share.
        pinterestDropdownText: 'Pinterest' // Pinterest dropdown text.

});
// ================================================================
// Page header
// ================================================================

// If page header exist
// =====================
if ($("#page-header").length) {
    $("body").addClass("page-header-on");

    // If full height enabled
    if ($("#page-header").hasClass("ph-full")) {
        $("body").addClass("ph-full-on");
    }

    // If position center enabled
    if ($("#page-header").hasClass("ph-center")) {
        $("body").addClass("ph-center-on");
    }

    // If page header image exist
    if ($(".ph-image").length) {
        $("body").addClass("ph-image-on");

        // If page header image is background image
        if ($("#page-header").hasClass("ph-bg-image")) {
            $("body").addClass("ph-bg-image-on");
        }
    }
}


// If page header background image is light
// =========================================
if ($("#page-header").is(".ph-bg-image.ph-bg-image-is-light")) {

    $(".ph-bg-image-is-light").on("mouseenter mouseover", function() {
        $("body").addClass("flux-light-bg-hover");
    }).on("mouseleave", function() {
        $("body").removeClass("flux-light-bg-hover");
    });

}
// ================================================================
// GSAP ScrollTrigger plugin
// More info: https://greensock.com/docs/v3/Plugins/ScrollTrigger/
// ================================================================

// Page header elements scrolling effects
// =======================================
if ($("#page-header").hasClass("ph-content-parallax")) {
    let tlPhParallax = gsap.timeline({ 
        scrollTrigger: {
            trigger: "#page-header", 
            start: 'top top', 
            end: 'bottom top', 
            scrub: true,
            markers: false
        }
    });

    // Page header caption elements
    if ($("#page-header").hasClass("ph-bg-image")) {
        if ($(".ph-caption").length) {
            if ($("#page-header").hasClass("ph-center")) {
                tlPhParallax.to(".ph-caption", { y: 180, opacity: 0, scale: 0.95, transformOrigin: "center" }, 0);
            } else {
                tlPhParallax.to(".ph-caption", { y: 180, opacity: 0, scale: 0.95, transformOrigin: "left" }, 0);
            }
        }
        if ($(".ph-image").length) {
            tlPhParallax.to(".ph-image-inner", { yPercent: 30 }, 0);
        }

    } else {

        if ($(".ph-categories").length) {
            tlPhParallax.to(".ph-categories", { y: -220 }, 0);
        }
        if ($(".ph-caption-subtitle").length) {
            tlPhParallax.to(".ph-caption-subtitle", { y: -230 }, 0);
        }
        if ($(".ph-caption-title").length) {
            tlPhParallax.to(".ph-caption-title", { y: -180 }, 0);
        }
        if ($(".ph-caption-description").length) {
            tlPhParallax.to(".ph-caption-description", { y: -120 }, 0);
        }
        if ($(".ph-meta").length) {
            tlPhParallax.to(".ph-meta", { y: -220 }, 0);
        }
        if ($(".ph-image").length) {
            tlPhParallax.to(".ph-image-inner", { y: -100 }, 0);
        }

        // Page header ghost
        var $phGhost = $(".ph-caption-title-ghost");
        if ($phGhost.length) {
            if (!$("#page-header").hasClass("ph-center")) {
                $phGhost.appendTo("#page-header");
                if ($("#page-header").hasClass("ph-ghost-scroll")) {
                    $phGhost.find(".ph-appear").wrapInner('<span class="phgh-text"></span>');
                    var $phghText = $phGhost.find(".phgh-text");
                    for (var i = 0; i < 3; i++) {
                        $phghText.clone().insertAfter($phghText);
                    }
                    tlPhParallax.to($phGhost, { y: -60, xPercent: -8 }, 0);
                } else {
                    tlPhParallax.to($phGhost, { y: -30 }, 0);
                }
            } else {
                tlPhParallax.to($phGhost, { y: -60 }, 0);
            }
        }

    }

    // Page header scroll down circle
    if ($(".flux-scroll-down").length) {
        gsap.to(".flux-scroll-down", { 
            // y: 100,
            autoAlpha: 0,
            ease: "none",
            scrollTrigger: {
                trigger: "#page-header",
                start: "top top",
                end: "30% top",
                scrub: true,
                markers: false
            }, 
        });
    }

    // Page header projekt share
    if ($(".ph-share").length) {
        gsap.to(".ph-share-inner", { 
            // y: 100,
            autoAlpha: 0,
            ease: "none",
            scrollTrigger: {
                trigger: "#page-header",
                start: "top top",
                end: "30% top",
                scrub: true,
                markers: false
            }, 
        });
    }
}

// If page header is visible
if ($("#page-header").length) {
    ScrollTrigger.create({
        trigger: "#page-header",
        start: "top bottom",
        end: "bottom 60px",
        scrub: true,
        markers: false,

        onLeave: () => phVisibleLeaveClass(),
        onEnter: () => phVisibleEnterClass(),
        onLeaveBack: () => phVisibleLeaveClass(),
        onEnterBack: () => phVisibleEnterClass(),
    });

    function phVisibleLeaveClass() {
        $("body").removeClass("flux-ph-visible");
    };
    function phVisibleEnterClass() {
        $("body").addClass("flux-ph-visible");
    };
}

// If page header background image is light
if ($("#page-header").is(".ph-bg-image.ph-bg-image-is-light")) {
    ScrollTrigger.create({
        trigger: "#page-header",
        start: "top bottom",
        end: "bottom 30px",
        scrub: true,
        markers: false,

        onLeave: () => phLightLeaveClass(),
        onEnter: () => phLightEnterClass(),
        onLeaveBack: () => phLightLeaveClass(),
        onEnterBack: () => phLightEnterClass(),
    });

    function phLightLeaveClass() {
        $("body").removeClass("flux-light-bg-on");
    };
    function phLightEnterClass() {
        $("body").addClass("flux-light-bg-on");
    };
}


// Portfolio grid categories filter show/hide on scroll
// =====================================================
if ($(".flux-grid-categories").length) {
    var $ttgCatTriggerWrap = $(".ttgr-cat-trigger-wrap");

    if ($ttgCatTriggerWrap.hasClass("ttgr-cat-fixed")) {
        $ttgCatTriggerWrap.appendTo("#body-inner");
        $("body").addClass("ttgr-cat-fixed-on");

        // Show/Hide trigger on page scroll
        ScrollTrigger.create({
            trigger: "#portfolio-grid",
            start: "top bottom",
            end: "bottom 75%",
            scrub: true,
            markers: false,

            onEnter: () => ttgCatShow(),
            onLeave: () => ttgCatHide(),
            onEnterBack: () => ttgCatShow(),
            onLeaveBack: () => ttgCatHide(),
        });

        function ttgCatShow() {
            gsap.to($ttgCatTriggerWrap, { duration: 0.4, autoAlpha: 1, scale: 1, ease:Power2.easeOut });
        }
        function ttgCatHide() {
            gsap.to($ttgCatTriggerWrap, { duration: 0.4, autoAlpha: 0, scale: 0.9, ease:Power2.easeOut });
        }

    } else {

        // Hide trigger before it reaches the top when page scroll
        gsap.to($ttgCatTriggerWrap, { 
            yPercent: 70,
            autoAlpha: 0,
            ease: "none",
            scrollTrigger: {
                trigger: $ttgCatTriggerWrap,
                start: "top 250px",
                end: "100px 250px",
                scrub: true,
                markers: false
            }, 
        });

    }
}


// Portfolio list item elements scrolling effects
// ===============================================
$(".pli-caption").each(function() {
    var $pliTitle = $(this).find(".pli-title");
    var $pliCategory = $(this).find(".pli-categories-wrap");
    var $pliCounter = $(this).parents(".portfolio-list-item").find(".pli-counter");

    let tl_plIInfo = gsap.timeline({
        scrollTrigger: {
            trigger: this,
            start: "top bottom",
            markers: false
        }
    });

    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function() {
            if ($($pliTitle).length) {
                tl_plIInfo.fromTo($pliTitle, { autoAlpha: 0, x: 60, scaleX: 1.3, transformOrigin: "left" }, { duration: 2, autoAlpha: 1, x: 0, scaleX: 1, transformOrigin: "left", ease: Expo.easeOut, clearProps:"all" }, "+=0.3");
            }
            if ($($pliCategory).length) {
                tl_plIInfo.fromTo($pliCategory, { autoAlpha: 0, x: 60, scaleX: 1.3, transformOrigin: "left" }, { duration: 2, autoAlpha: 1, x: 0, scaleX: 1, transformOrigin: "left", ease: Expo.easeOut, clearProps:"all" }, "-=1.7");
            }
            if ($($pliCounter).length) {
                tl_plIInfo.fromTo($pliCounter, { autoAlpha: 0, x: 60, scaleX: 1.3, transformOrigin: "left" }, { duration: 2, autoAlpha: 1, x: 0, scaleX: 1, transformOrigin: "left", ease: Expo.easeOut, clearProps:"all" }, "-=1.8");
            }
        },

        "(max-width: 767px)": function() {
            if ($($pliTitle).length) {
                tl_plIInfo.from($pliTitle, { duration: 2, autoAlpha: 0, y: 40, ease: Expo.easeOut, clearProps:"all" }, "+=0.5");
            }
            if ($($pliCategory).length) {
                tl_plIInfo.from($pliCategory, { duration: 2, autoAlpha: 0, y: 20, ease: Expo.easeOut, clearProps:"all" }, "-=1.7");
            }
        }
    });
});


// Image parallax
// ===============
$(".anim-image-parallax").each(function() {

    // Add wrap <div>.
    $(this).wrap('<div class="anim-image-parallax-wrap"><div class="anim-image-parallax-inner"></div></div>');

    // Add overflow hidden.
    $(".anim-image-parallax-wrap").css({ "overflow": "hidden" });

    var $animImageParallax = $(this);
    var $aipWrap = $animImageParallax.parents(".anim-image-parallax-wrap");
    var $aipInner = $aipWrap.find(".anim-image-parallax-inner");

    let tl_ImageParallax = gsap.timeline({
        scrollTrigger: {
            trigger: $aipWrap,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
            markers: false,
            onEnter: () => animImgParallaxRefresh(),
        },
    });
    tl_ImageParallax.to($animImageParallax, { yPercent: 30, ease: "none" });

    function animImgParallaxRefresh() {
        tl_ImageParallax.scrollTrigger.refresh();
    }

    // Zoom in
    let tl_aipZoomIn = gsap.timeline({
        scrollTrigger: {
            trigger: $aipWrap,
            start: "top 90%",
            markers: false,
        }
    });
    tl_aipZoomIn.from($aipInner, { duration: 1.5, autoAlpha: 0, scale: 1.2, ease: Power2.easeOut, clearProps:"all" });
    
});


// flux-Grid "layout-creative" parallax
// ===================================
ScrollTrigger.matchMedia({
    "(min-width: 768px)": function() {
        $(".flux-grid.ttgr-layout-creative-1 .flux-grid-item:nth-of-type(6n+2) .ttgr-item-inner, .flux-grid.ttgr-layout-creative-1 .flux-grid-item:nth-of-type(6n+4) .ttgr-item-inner, .flux-grid.ttgr-layout-creative-2 .flux-grid-item:nth-of-type(4n+2) .ttgr-item-inner, .flux-grid.ttgr-layout-creative-2 .flux-grid-item:not(:last-child):nth-of-type(4n+3) .ttgr-item-inner").each(function() {
            var $this = $(this);

            gsap.to($this, {
                yPercent: -50,
                ease: "none",
                scrollTrigger: {
                    trigger: $this,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                    markers: false,
                    onEnter: () => ttgrItemParalRefresh(),
                }, 
            });

            // Refresh start/end positions on enter.
            function ttgrItemParalRefresh() {
                ScrollTrigger.refresh(true);
            };

        });
    }
});


// Next project parallax
// ======================
if ($(".flux-np-image").length) {
    gsap.from(".flux-np-image", {
        yPercent: -30,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
            trigger: ".flux-next-project",
            start: "5% bottom",
            end: "bottom bottom",
            scrub: true,
            markers: false,
        }, 
    });

    if ($(".flux-np-caption").length) {
        gsap.from(".flux-np-caption", {
            yPercent: -70,
            opacity: 0,
            scale: 0.96,
            transformOrigin: "left",
            ease: "none",
            scrollTrigger: {
                trigger: $(".flux-next-project"),
                start: "15% bottom",
                end: "bottom bottom",
                scrub: true,
                markers: false,
            }, 
        });
    }

} else {

    if ($(".flux-np-caption").length) {
        gsap.from(".flux-np-caption", {
            yPercent: -10,
            opacity: 0,
            scale: 0.96,
            ease: "none",
            scrollTrigger: {
                trigger: $(".flux-next-project"),
                start: "40% bottom",
                end: "bottom bottom",
                scrub: true,
                markers: false,
            }, 
        });
    }
}

// If next project is touches the top of the page
if ($(".flux-next-project").length) {
    ScrollTrigger.create({
        trigger: ".flux-next-project",
        start: "top 90px",
        end: "bottom top",
        scrub: true,
        markers: false,

        onLeave: () => ttNpTopLeaveClass(),
        onEnter: () => ttNpTopEnterClass(),
        onLeaveBack: () => ttNpTopLeaveClass(),
        onEnterBack: () => ttNpTopEnterClass(),
    });

    function ttNpTopLeaveClass() {
        $("body").removeClass("flux-next-project-top");
    };
    function ttNpTopEnterClass() {
        $("body").addClass("flux-next-project-top");
    };
}

// If next project background image is light
if ($(".flux-next-project").hasClass("flux-np-image-is-light")) {
    ScrollTrigger.create({
        trigger: ".flux-next-project",
        start: "top 90px",
        end: "bottom top",
        scrub: true,
        markers: false,

        onLeave: () => ttNpLightLeaveClass(),
        onEnter: () => ttNpLightEnterClass(),
        onLeaveBack: () => ttNpLightLeaveClass(),
        onEnterBack: () => ttNpLightEnterClass(),
    });

    function ttNpLightLeaveClass() {
        $("body").removeClass("flux-light-bg-on");
    };
    function ttNpLightEnterClass() {
        $("body").addClass("flux-light-bg-on");
    };

    // Hover
    $(".flux-next-project").on("mouseenter mouseover", function() {
        $("body").addClass("flux-light-bg-hover");
    }).on("mouseleave", function() {
        $("body").removeClass("flux-light-bg-hover");
    });

}


// Skew on scroll (https://codepen.io/GreenSock/pen/eYpGLYL)
// ===============
if(!isMobile) { // Not for mobile devices!

    var skewElement = $(".skew-on-scroll"); // Skew element class.
    var skewMaxAngle = 7; // Max angle.
    var skewVelocity = 500; // Velocity.
    var skewDuration = 0.5; // Duration.

    var proxy = { skew: 0 },
            skewSetter = gsap.quickSetter(skewElement, "skewY", "deg"),
            clamp = gsap.utils.clamp(-skewMaxAngle, skewMaxAngle);
    
    if (skewElement.length) {

        function skewer() {
            ScrollTrigger.create({
                onUpdate: (self) => {
                    var skew = clamp(self.getVelocity() / -skewVelocity);
                    if (Math.abs(skew) > Math.abs(proxy.skew)) {
                        proxy.skew = skew;
                        gsap.to(proxy, {skew: 0, duration: skewDuration, ease: "power1.easeInOut", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
                    }
                }
            });
        }

        function skewerIfSmoothScroll() {
            var bodyScrollBar = Scrollbar.init(document.getElementById("scroll-container"));

            ScrollTrigger.scrollerProxy("#scroll-container", {
                scrollTop(value) {
                    if (arguments.length) {
                        bodyScrollBar.scrollTop = value;
                    }
                    return bodyScrollBar.scrollTop; 
                }
            });

            ScrollTrigger.create({
                scroller: "#scroll-container",
                onUpdate: (self) => {
                    var skew = clamp(self.getVelocity() / -skewVelocity);
                    if (Math.abs(skew) > Math.abs(proxy.skew)) {
                        proxy.skew = skew;
                        gsap.to(proxy, {skew: 0, duration: skewDuration, ease: "power1.easeInOut", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
                    }
                }
            });
        }

        if ($("body").hasClass("flux-smooth-scroll")) {
            if(isMobile) {
                skewer();
            } else {
                skewerIfSmoothScroll();
            } 
        } else {
            skewer();
        }

        gsap.set(skewElement, {transformOrigin: "center center", force3D: true}); // Change transform origin if needed.
    }

}


// Scrolling button
// =================
if ($(".flux-scrolling-btn").length) {
    $(".flux-scrolling-btn").each(function() {
        var $this = $(this);
        var $scrBtnSvg = $this.find(".scr-btn-spinner");
        gsap.from($scrBtnSvg, {
            rotate: 240,
            ease: "none",
            scrollTrigger: {
                trigger: $scrBtnSvg,
                start: "-40% bottom",
                end: "120% top",
                scrub: true,
                markers: false,
            }, 
        });
    });
}


// Appear on scroll
// =================

// zoom in
$(".anim-zoomin").each(function() {

    // Add wrap <div>.
    $(this).wrap('<div class="anim-zoomin-wrap"></div>');

    // Add overflow hidden.
    $(".anim-zoomin-wrap").css({ "overflow": "hidden" })

    var $this = $(this);
    var $asiWrap = $this.parents(".anim-zoomin-wrap");

    let tl_ZoomIn = gsap.timeline({
        scrollTrigger: {
            trigger: $asiWrap,
            start: "top bottom",
            markers: false,
            onEnter: () => animZoomInRefresh(),
        }
    });
    tl_ZoomIn.from($this, { duration: 1.5, autoAlpha: 0, scale: 1.2, ease: Power2.easeOut, clearProps:"all" });

    // Refresh start/end positions on enter.
    function animZoomInRefresh() {
        ScrollTrigger.refresh();
    };
});


// fade in-up
$(".anim-fadeinup").each(function() {
    let tl_FadeInUp = gsap.timeline({
        scrollTrigger: {
            trigger: this,
            start: "top bottom",
            markers: false
        }
    });

    tl_FadeInUp.from(this, { duration: 2.5, autoAlpha: 0, y: 100, ease: Expo.easeOut, clearProps:"all" }, "+=0.3");
});


// skew in-up
$(".anim-skewinup").each(function() {
    let tl_SkewInUp = gsap.timeline({
        scrollTrigger: {
            trigger: this,
            start: "top bottom",
            markers: false
        }
    });

    tl_SkewInUp.from(this, { duration: 2, skewY: 5, transformOrigin: "left top", autoAlpha: 0, y: 100, ease: Expo.easeOut, clearProps:"all" }, "+=0.3");
});
// ================================================================
// Scroll between anchors 
// Requires "Smooth Scrollbar" (https://github.com/idiotWu/smooth-scrollbar/blob/develop/docs/api.md#scrollbarscrollintoview)
// ================================================================

$('a[href^="#"]')
    .not('[href$="#"]') // omit from selection
    .not('[href$="#0"]') // omit from selection
    .on("click", function() {

    var target = this.hash;

    // If fixed header position enabled.
    if ($("#flux-header").hasClass("flux-header-fixed")) {
        var $offset = $("#flux-header").height();
    } else {
        var $offset = 0;
    }

    // You can use data attribute (for example: data-offset="100") to set top offset in HTML markup if needed. 
    if ($(this).data("offset") != undefined) $offset = $(this).data("offset");
    
    if(!isMobile) { // Not for mobile devices!
        if ($("body").hasClass("flux-smooth-scroll")) {
            var topY = $(target).offset().top - $("#scroll-container > .scroll-content").offset().top - $offset;
            var $scrollbar = Scrollbar.init(document.getElementById("scroll-container"));
            gsap.to($scrollbar, { duration: 1.5, scrollTo: { y: topY, autoKill: true }, ease: Expo.easeInOut });

        } else {
            var topY = $(target).offset().top - $("body").offset().top - $offset;
            $("html,body").animate({scrollTop: topY}, 800);
        }
    } else {
        var topY = $(target).offset().top - $("body").offset().top - $offset;
        $("html,body").animate({scrollTop: topY}, 800);
    }
    return false;
});
// ================================================================
// Forms
// ================================================================

// Remove input placeholder on focus
$("input,textarea").focus(function () {
    $(this).data("placeholder", $(this).attr("placeholder"))
        .attr("placeholder", "");
}).blur(function () {
    $(this).attr("placeholder", $(this).data("placeholder"));
}); 

// Form "Browse File" button info
$(document).on("change", ":file", function() {
    var input = $(this),
    numFiles = input.get(0).files ? input.get(0).files.length : 1,
    label = input.val().replace(/\\/g, "/").replace(/.*\//, "");
    input.trigger("fileselect", [numFiles, label]);
});
$(":file").on("fileselect", function(event, numFiles, label) {
    var input = $(this).parents(".flux-form-file").find(".flux-file-info"),
    log = numFiles > 1 ? numFiles + " files selected" : label;

    if( input.length ) {
        input.val(log);
    } else {
        if( log ) alert(log);
    }
});


// Universal PHP Mail Feedback Script 
// (https://github.com/agragregra/uniMail)
// ========================================

// E-mail Ajax Send
$("#flux-contact-form").submit(function() { // Change (your contact form ID)
    var th = $(this);
    $.ajax({
        type: "POST",
        url: "mail.php", // Change (mail.php path)
        data: th.serialize()
    }).done(function() {
        alert("Thank you. Your message has been sent!");
        setTimeout(function() {
        // Done Functions
        th.trigger("reset");
        }, 800);
    });
    return false;
});
// ================================================================
// Miscellaneous
// ================================================================

// flux-Button disabled (prevent click)
// ===================
$(".flux-btn-disabled").on("click", function() {
    return false;
});


// Force page scroll position to top on refresh (do not remove!)
// =============================================
$(window).on("pagehide", function(){
    $(window).scrollTop(0);
});


// Hover fix for iOS
// ==================
$("*").on("touchstart",function() {
    $(this).trigger("hover");
}).on("touchend",function() {
    $(this).trigger("hover");
});
// ================================================================
// Scroll to top
// Requires "GSAP ScrollToPlugin" (https://greensock.com/docs/v2/Plugins/ScrollToPlugin)
// ================================================================

$(".scroll-to-top").on("click", function() {
    if(!isMobile) { // Not for mobile devices!
        if ($("body").hasClass("flux-smooth-scroll")) {
            var $scrollbar = Scrollbar.init(document.getElementById("scroll-container"));
            gsap.to($scrollbar, { duration: 1.5, scrollTo: { y: 0, autoKill: true }, ease: Expo.easeInOut });
        } else {
            $("html,body").animate({scrollTop: 0}, 800);
        }
    } else {
        $("html,body").animate({scrollTop: 0}, 800);
    }
    return false;
});
})(jQuery);
// Wrap the code inside a function
function initializeSwiper() {
  const swiper = new Swiper(".swiper-blocks", {
    loop: true,
    lazy: true,
    autoplay: false,
    slidesPerView: 'auto',
    spaceBetween: 20,
    grabCursor: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    initialSlide: 1,
    breakpoints: {
      480: {
        slidesPerView: 1
      },
      640: {
        slidesPerView: 1
      },
      768: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 2
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      dynamicBullets: true,
      clickable: true
    },
    keyboard: false,
    mousewheel: false
  });

  swiper.on('resize', function () {
    const containerWidth = this.$el[0].clientWidth;
    const slideWidth = this.width / this.params.slidesPerView;
    const buttonWidth = (containerWidth - slideWidth) / 2;
    const padding = 70; // adjust this to change the padding from the edge of the slide

    const prevButton = document.querySelector('.swiper-button-prev-custom');
    const nextButton = document.querySelector('.swiper-button-next-custom');

    prevButton.style.left = `${buttonWidth - prevButton.offsetWidth + padding}px`;
    nextButton.style.right = `${buttonWidth - nextButton.offsetWidth + padding}px`;
  });

  swiper.emit('resize'); // Trigger resize event initially
}

// Call the initializeSwiper function after the swiper block is initialized
document.addEventListener('DOMContentLoaded', function() {
  const swiperBlocks = document.getElementsByClassName('swiper-blocks');
  if (swiperBlocks.length > 0) {
    initializeSwiper();
  }
});
function changeLanguage(language) {
  event.preventDefault();
    // Update the HTML lang attribute
  document.documentElement.lang = language;

  // Save the selected language in Local Storage
  localStorage.setItem('selectedLanguage', language);

  // Image elements to translate
    const imagesToTranslate = document.querySelectorAll('img[data-src-pt]');

    // Get all language links
    const languageLinks = document.querySelectorAll('.language a');

    // Remove active class from all links
    languageLinks.forEach(link => link.classList.remove('active'));

    // Find the selected link and add active class to it
    const selectedLink = document.querySelector(`.language a[href='#'][onclick="changeLanguage('${language}')"]`);
    selectedLink.classList.add('active');

    // Translate content based on the selected language
  const elementsToTranslate = document.querySelectorAll('[data-pt], [data-placeholder-pt], [data-ch], [data-placeholder-ch]');

  imagesToTranslate.forEach((img) => {
    if (language === 'pt') {
      // Save the original src in a new data attribute
      if (!img.getAttribute('data-src-original')) {
        img.setAttribute('data-src-original', img.src);
      }

      // Translate to Portuguese
      const translation = img.getAttribute('data-src-pt');
      if (translation) {
        img.src = translation;
      }
    } else if (language === 'ch') {
      // Handle Chinese translation logic
      const translation = img.getAttribute('data-src-ch');
      if (translation) {
        img.src = translation;
      }
    } else if (language === 'en') {
      // Get the original src from the data attribute
      const originalSrc = img.getAttribute('data-src-original');
      if (originalSrc) {
        img.src = originalSrc;
      }
    }
  });



    elementsToTranslate.forEach((element) => {
      if (language === 'en') {
        // Restore the original English text
        const originalText = element.getAttribute('data-original');
        if (originalText) {
          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            element.value = originalText;
          } else {
            element.innerHTML = originalText;
          }
        }

        // Restore the original hover text
        if (element.hasAttribute('data-hover-original')) {
          element.setAttribute('data-hover', element.getAttribute('data-hover-original'));
        }

        // Restore the original placeholder text using data-placeholder-en
        const originalPlaceholder = element.getAttribute('data-placeholder-en');
        if (originalPlaceholder) {
          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            element.setAttribute('placeholder', originalPlaceholder);
          } else {
            element.innerHTML = originalPlaceholder;
          }
        }
      } else {
        // Save the original English content as data-original if not already saved
        if (!element.hasAttribute('data-original')) {
          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            element.setAttribute('data-original', element.value);
          } else {
            element.setAttribute('data-original', element.innerHTML);
          }
        }

        // Handle translation logic for other languages ('pt', 'ch', etc.)
        const translation = element.getAttribute(`data-${language}`);
        if (translation) {
          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            element.value = translation;
          } else {
            element.innerHTML = translation;
          }
        }

        // Handle hover text
        const hoverTranslation = element.getAttribute(`data-hover-${language}`);
        if (hoverTranslation) {
          element.setAttribute('data-hover', hoverTranslation);
        }

        // Handle placeholder text using data-placeholder-en for English
        const placeholderTranslation = element.getAttribute(`data-placeholder-${language}`);
        if (placeholderTranslation) {
          if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            element.setAttribute('placeholder', placeholderTranslation);
          } else {
            element.innerHTML = placeholderTranslation;
          }
        }
      }
    });








}

window.onload = function () {
  // Always load 'en' language
  changeLanguage('en');
};


// window.onload = function () {
  // Check if a language is saved in Local Storage
  // const savedLanguage = localStorage.getItem('selectedLanguage');

  // If there is a saved language, set the page's language to that
  // if (savedLanguage) {
  //  changeLanguage(savedLanguage);
  // } else {
    // Default to 'en' if no language is saved
   // changeLanguage('en');
  //}
//};

// window.onload = function() {
//   // Check if a language is saved in Local Storage
//   const savedLanguage = localStorage.getItem('selectedLanguage');

//   // If there is a saved language, set the page's language to that
//   if (savedLanguage) {
//     changeLanguage(savedLanguage);
//   }
// };
void (function (root, factory) {
    if (typeof define === 'function' && define.amd) define(factory)
    else if (typeof exports === 'object') module.exports = factory()
    else root.CookieNotice = factory()
}(this, function () {
    function CookieNotice() {
        ready(run)
    }

    CookieNotice.options = {
        message: '<p>This website uses cookies to help us give you the best experience when you visit. By using this website you consent to our use of these cookies and agree with our <a href="privacy">privacy policy</a>.</p>',
        dismiss: 'Dismiss'
    }

    // Check if the current URL is the other page
    if (window.location.href.endsWith('pt')) {
        // Set the message for the other page
        CookieNotice.options.message = '<p>Este website utiliza cookies para oferecer a melhor experiência na sua visita. Ao continuar a navegar consente a utilização de cookies e concorda com a nossa <a href="privacidade">política de privacidade</a>.</p>';
        CookieNotice.options.dismiss = 'Concordo';
    }

    // Check if the current URL is the other page
    if (window.location.href.endsWith('privacidade')) {
        // Set the message for the other page
        CookieNotice.options.message = '<p>Este website utiliza cookies para oferecer a melhor experiência na sua visita. Ao continuar a navegar consente a utilização de cookies e concorda com a nossa <a href="privacidade">política de privacidade</a>.</p>';
        CookieNotice.options.dismiss = 'Concordo';
    }

    function run() {
        if (window.localStorage.cookieNoticeDismissed) return
        show()
    }

    function dismiss() {
        var notice = document.getElementById('cookie-notice')
        if (notice) notice.parentNode.removeChild(notice)
        window.localStorage.cookieNoticeDismissed = true
    }

    function undismiss() {
        delete window.localStorage.cookieNoticeDismissed
    }

    function show() {
        var $div = document.createElement('div')
        $div.className = 'cookie-notice'
        $div.id = 'cookie-notice'

        var $message = document.createElement('div')
        $message.className = 'cookie-notice-message'
        $message.innerHTML = CookieNotice.options.message
        $div.appendChild($message)

        var $dismiss = document.createElement('button')
        $dismiss.innerHTML = CookieNotice.options.dismiss
        $dismiss.onclick = dismiss
        $div.appendChild($dismiss)

        document.body.appendChild($div)
    }

    function ready(fn) {
        if (document.readyState === 'complete') {
            return fn()
        } else if (document.addEventListener) {
            document.addEventListener('DOMContentLoaded', fn)
        } else {
            document.attachEvent('onreadystatechange', function () {
                if (document.readyState === 'interactive') fn()
            })
        }
    }

    CookieNotice.run = run
    CookieNotice.dismiss = dismiss
    CookieNotice.undismiss = undismiss

    return CookieNotice
}));

CookieNotice()
document.addEventListener('DOMContentLoaded', function () {
  const openModalButton = document.getElementById('openModalButton');
  const videoModal = document.getElementById('videoModal');
  const closeModal = document.getElementById('closeModal');
  const vimeoVideoContainer = document.getElementById('vimeoPlayer'); // Updated element ID

  let player;

  // Add an event listener to the button to open the modal
  openModalButton.addEventListener('click', function () {
    videoModal.style.display = 'block';

    // Initialize the Vimeo player with your video ID
    player = new Vimeo.Player(vimeoVideoContainer, {
      id: '878373628'
    });

    // Play the video when it's loaded
    player.ready().then(function () {
      player.play();
    });
  });

  // Add an event listener to the close button to close the modal
  closeModal.addEventListener('click', function () {
    videoModal.style.display = 'none';

    // Unload the video to stop playback
    if (player) {
      player.unload();
    }
  });
});
// Wait for the DOM to be ready
document.addEventListener('DOMContentLoaded', function () {
  // Create a map and set its view to a specific geographical coordinate and zoom level.
  var myMap = L.map('map', {
    center: [37.066186835528526, -8.808790435360242],
    zoom: 10,
    scrollWheelZoom: false,
    doubleClickZoom: false,
  });

  var locationIcon = L.icon({
    iconUrl: 'img/location.png',
    iconSize: [100],
    iconAnchor: [25, 80],
    popupAnchor: [135, 0],
  });

  // Add a tile layer for the map using a base map provider (e.g., OpenStreetMap).
  var tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(myMap);

  // Add a marker to the map at a specific location.
  var marker = L.marker([37.066186835528526, -8.808790435360242], { icon: locationIcon }).addTo(myMap);

  // Add a popup to the marker.
  var popup = marker.bindPopup('<b>Praia da Boca do Rio</b>', { className: 'custom-map-popup' });

  // Remove the tile layer from the map
  myMap.removeControl(myMap.attributionControl);

  // Calculate the vertical offset (20% of map height)
  var offsetPercentage = -0.18; // 20%
  var mapHeight = myMap.getSize().y;
  var verticalOffset = mapHeight * offsetPercentage;

  // Shift the map view down
  myMap.panBy([0, verticalOffset]);

  // Check if the device is a mobile device
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Nokia|Opera Mini/i.test(navigator.userAgent);
  }

  // Open the popup on desktop, close on mobile
  if (!isMobileDevice()) {
    popup.openPopup();
  }
});
function openModal(event, modalId) {
  event.preventDefault(); // Prevent the default behavior
  var modal = document.getElementById(modalId);
  modal.style.top = '0'; // Slide the modal down
  document.body.style.overflow = 'hidden'; // Disable scrolling in the background
}

function closeModal(modalId) {
  var modal = document.getElementById(modalId);
  modal.style.top = '-100%'; // Slide the modal up above the viewport
  document.body.style.overflow = 'auto'; // Re-enable scrolling
}

var openHistoryButton = document.getElementById('openHistoryModal');
var openArtisticButton = document.getElementById('openArtisticModal');
var openLegendButton = document.getElementById('openLegendModal');
var closeButtons = document.getElementsByClassName('cards-modal-close');

if (openHistoryButton) {
  openHistoryButton.addEventListener('click', function (event) {
    openModal(event, 'history-modal');
  });
}

if (openArtisticButton) {
  openArtisticButton.addEventListener('click', function (event) {
    openModal(event, 'artistic-modal');
  });
}

if (openLegendButton) {
  openLegendButton.addEventListener('click', function (event) {
    openModal(event, 'legend-modal');
  });
}

// Add event listeners to all elements with the class 'cards-modal-close'
for (var i = 0; i < closeButtons.length; i++) {
  closeButtons[i].addEventListener('click', function () {
    closeModal(this.parentElement.id);
  });
}
document.addEventListener('DOMContentLoaded', function () {
  let swiperLicenses; // Declare the swiperLicenses variable in a higher scope

  // Initialize Swiper instance
  swiperLicenses = new Swiper('.swiper-licenses', {
    // Swiper configuration options
    initialSlide: 0,
    loop: true,
    lazy: true,
    grabCursor: true,
    effect: 'creative',
    creativeEffect: {
      prev: {
        shadow: false,
        translate: ['-20%', 0, -1],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    },
    breakpoints: {
      640: {},
      768: {},
      1024: {},
    },
    navigation: {
      nextEl: '.swiper-navigation .swiper-button-next',
      prevEl: '.swiper-navigation .swiper-button-prev',
    },
    pagination: {
      el: '.swiper-licenses .swiper-pagination',
      type: 'bullets',
      dynamicBullets: false,
      clickable: true,
    },
    keyboard: false,
    mousewheel: false,
  });

  // Get the title and subtitle elements
  const swiperTitle = document.querySelector('.swiper-title .headline');
  const swiperSubTitle = document.querySelector('.swiper-title .sub-headline');

  // Get the language buttons
  const englishButton = document.getElementById('english');
  const portugueseButton = document.getElementById('portuguese');
  const chineseButton = document.getElementById('chinese');

  // Function to update the title and subtitle
  function updateTitleAndSubtitle(lang) {
    const currentSlide = swiperLicenses.slides[swiperLicenses.activeIndex];
    let dataSlideTitle, dataSlideSubTitle;

    if (lang === 'en') {
      dataSlideTitle = currentSlide.getAttribute('data-slide-title');
      dataSlideSubTitle = currentSlide.getAttribute('data-slide-subtitle');
    } else {
      dataSlideTitle = currentSlide.getAttribute(`data-slide-title-${lang}`);
      dataSlideSubTitle = currentSlide.getAttribute(`data-slide-subtitle-${lang}`);
    }

    swiperTitle.textContent = dataSlideTitle;
    swiperSubTitle.textContent = dataSlideSubTitle;

    // Store the selected language in local storage
    localStorage.setItem('selectedLanguage', lang);
  }

  // Initialize the title with the selected or default language
  const savedLanguage = localStorage.getItem('selectedLanguage');
  if (savedLanguage) {
    updateTitleAndSubtitle(savedLanguage);
  } else {
    // Default to 'en' if no language is saved
    updateTitleAndSubtitle('en');
  }

  // Update the title and subtitle when the slide changes
  swiperLicenses.on('slideChange', () => {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    updateTitleAndSubtitle(selectedLanguage);
  });

  // Event listeners for language buttons
  englishButton.addEventListener('click', () => {
    updateTitleAndSubtitle('en');
  });

  portugueseButton.addEventListener('click', () => {
    updateTitleAndSubtitle('pt');
  });

  chineseButton.addEventListener('click', () => {
    updateTitleAndSubtitle('ch');
  });

  // Function to programmatically trigger hover effect on a hotspot
  function triggerHotspotHover(index) {
    const hotspot = hotspots[index - 1]; // Subtract 1 to match 0-based index
    const hotspotRect = hotspot.getBoundingClientRect();
    const containerRect = hotspot.parentElement.getBoundingClientRect();
    const speechBubble = speechBubbles[index - 1]; // Subtract 1 to match 0-based index

    const hotspotX = hotspotRect.left - containerRect.left;
    const hotspotY = hotspotRect.top - containerRect.top;

    speechBubble.style.display = 'block';
    speechBubble.style.top = `${hotspotY - speechBubble.clientHeight - 15}px`;
    speechBubble.style.left = `${hotspotX - speechBubble.clientWidth / 2 + hotspot.clientWidth / 2}px`;
  }

  // Update the title and show the corresponding speech bubble when the slide changes
  swiperLicenses.on('slideChange', () => {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    updateTitleAndSubtitle(selectedLanguage);

    const currentSlideIndex = swiperLicenses.activeIndex;
    hideAllSpeechBubbles();
    triggerHotspotHover(currentSlideIndex);
  });

  // Function to hide all speech bubbles
  function hideAllSpeechBubbles() {
    speechBubbles.forEach((speechBubble) => {
      speechBubble.style.display = 'none';
    });
  }

  function updateTitle() {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
    updateTitleAndSubtitle(selectedLanguage);
  }


  // Update the title and show the corresponding speech bubble when the slide changes
  swiperLicenses.on('slideChange', () => {
    updateTitle();
    const currentSlideIndex = swiperLicenses.activeIndex;
    hideAllSpeechBubbles(); // Hide all speech bubbles
    triggerHotspotHover(currentSlideIndex); // Show the selected speech bubble
  });

  // Hotspot and Slide change code
  const hotspots = document.querySelectorAll('.hot-spot');
  const speechBubbles = document.querySelectorAll('.speech-bubble');

  hotspots.forEach((hotspot, index) => {
    hotspot.addEventListener('mouseenter', () => {
      const hotspotRect = hotspot.getBoundingClientRect();
      const containerRect = hotspot.parentElement.getBoundingClientRect();
      const speechBubble = speechBubbles[index];

      const hotspotX = hotspotRect.left - containerRect.left;
      const hotspotY = hotspotRect.top - containerRect.top;

      speechBubble.style.display = 'block';
      speechBubble.style.top = `${hotspotY - speechBubble.clientHeight - 15}px`;
      speechBubble.style.left = `${hotspotX - speechBubble.clientWidth / 2 + hotspot.clientWidth / 2}px`;

      // Change the active slide of the carousel when a hotspot is clicked
      hotspot.addEventListener('click', () => {
        const slideIndex = parseInt(hotspot.getAttribute('data-slide'), 10);
        swiperLicenses.slideTo(slideIndex);
      });
    });

    hotspot.addEventListener('mouseleave', () => {
      speechBubbles[index].style.display = 'none';
    });
  });
});
document.querySelectorAll('.button-download').forEach(button => {

    let duration = 3000,
        svg = button.querySelector('svg'),
        svgPath = new Proxy({
            y: null,
            smoothing: null
        }, {
            set(target, key, value) {
                target[key] = value;
                if(target.y !== null && target.smoothing !== null) {
                    svg.innerHTML = getPath(target.y, target.smoothing, null);
                }
                return true;
            },
            get(target, key) {
                return target[key];
            }
        });

    button.style.setProperty('--duration', duration);

    svgPath.y = 20;
    svgPath.smoothing = 0;

    button.addEventListener('click', e => {

        e.preventDefault();

        if(!button.classList.contains('loading')) {

            button.classList.add('loading');

            gsap.to(svgPath, {
                smoothing: 0.3,
                duration: duration * 0.065 / 1000
            });

            gsap.to(svgPath, {
                y: 12,
                duration: duration * 0.265 / 1000,
                delay: duration * 0.065 / 1000,
                ease: Elastic.easeOut.config(1.12, 0.4)
            });

            setTimeout(() => {
                svg.innerHTML = getPath(0, 0, [
                    [3, 14],
                    [8, 19],
                    [21, 6]
                ]);
                initiateDownload('img/brochure.pdf', 'brochure.pdf');

            }, duration / 2);

        }

    });

});

function getPoint(point, i, a, smoothing) {
    let cp = (current, previous, next, reverse) => {
        let p = previous || current,
            n = next || current,
            o = { // Changed variable name from '-' to 'o'
                length: Math.sqrt(Math.pow(n[0] - p[0], 2) + Math.pow(n[1] - p[1], 2)),
                angle: Math.atan2(n[1] - p[1], n[0] - p[0])
            },
            angle = o.angle + (reverse ? Math.PI : 0),
            length = o.length * smoothing;
        return [current[0] + Math.cos(angle) * length, current[1] + Math.sin(angle) * length];
    },
    cps = cp(a[i - 1], a[i - 2], point, false),
    cpe = cp(point, a[i - 1], a[i + 1], true);
    return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
}

function getPath(update, smoothing, pointsNew) {
    let points = pointsNew ? pointsNew : [
        [4, 12],
        [12, update],
        [20, 12]
    ];
    let d = points.reduce((acc, point, i, a) => i === 0 ? `M ${point[0]},${point[1]}` : `${acc} ${getPoint(point, i, a, smoothing)}`, '');
    return `<path d="${d}" />`;
}

function initiateDownload(url, filename) {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
}